import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, input, signal, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { catchError } from 'rxjs';
import { ApiService } from '../../../../core/services/api.service';
import { AuthService } from '../../../../core/services/auth.service';
import { CheckoutService } from '../../../../core/services/checkout.service';
import { DataApiStateService } from '../../../../core/services/data-api-state.service';
import { EmitEvent, EventBusService, Events } from '../../../../core/services/event-bus.service';
import { GeneralService } from '../../../../core/services/general.service';
import { ToastService } from '../../../../core/services/toast.service';
import { BuyPackageAddonDialogComponent } from '../../../../shared/components/dialogs/buy-package-addon-dialog/buy-package-addon-dialog.component';
import { ToastMessages } from '../../../../shared/models/toast-messages';
import { IBasket, IBasketItemDto, IRemoveFromBasketResponse, IUpdateBasketItemResponse } from '@index';

@Component({
    selector: 'app-checkout-sidebar-basket-item',
    standalone: true,
    imports: [
        CommonModule,
        ButtonModule,
    ],
    templateUrl: './checkout-sidebar-basket-item.component.html',
    styleUrl: './checkout-sidebar-basket-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutSidebarBasketItemComponent implements OnInit {
    @Input() basketItem: IBasketItemDto = {} as IBasketItemDto;
    productViewType = input<'default' | 'mini'>('default');
    sidebarShowDeleteCartItemsButton = input(true);
    disabledQuantityButtons = signal(false);
    targetStudents: any[] | null | undefined = [];
  
    readonly services = {
      checkout: inject(CheckoutService),
      eventBus: inject(EventBusService),
      general: inject(GeneralService),
      toast: inject(ToastService),
      auth: inject(AuthService),
      api: inject(ApiService),
      confirmation: inject(ConfirmationService),
    };
    private readonly destroy: DestroyRef = inject(DestroyRef);
  
    ngOnInit(): void {
      this.targetStudents = this.basketItem.targetStudents ?? [];
    }
  
    openAddonDialog(product: IBasketItemDto): void {
      this.services.general.openComponent(BuyPackageAddonDialogComponent, { basketItem: product });
    }
  
    confirmRemoveItemFromBasket(basketItem: IBasketItemDto): void {
      this.services.confirmation.confirm({
        header: 'Are you sure you want to remove the item?',
        message: `<b>${this.services.checkout.buildBasketItemTitle(basketItem)}</b> will be removed from the cart.`,
        accept: () => this.removeBasketItemFromBasket(basketItem.id!),
      });
    }
  
    increment(product: IBasketItemDto): void {
      console.log(product);
      this.disabledQuantityButtons.set(true);
      this.updateBasketItemQuantity(product.id!, 1, product.hasExtension);
    }
  
    decrement(product: IBasketItemDto): void {
      if (product.quantity! > 1) {
        this.disabledQuantityButtons.set(true);
        this.updateBasketItemQuantity(product.id!, -1, product.hasExtension);
      }
    }
  
    private removeBasketItemFromBasket(basketItemId: string): void {
      this.apiCall<IRemoveFromBasketResponse>('DELETE', IBasket.deleteBasketItem, { basketItemId });
    }
  
    private updateBasketItemQuantity(basketItemId: string, change: number, hasExtension: boolean): void {
      this.apiCall<IUpdateBasketItemResponse>('PATCH', IBasket.patchUpdateBasketItem, { basketItemId, quantity: change, hasExtension });
    }
  
    private apiCall<T>(method: 'DELETE' | 'PATCH', url: string, params: object): void {
      this.services.api.getApiData<T>({ url, method }, params)
        .pipe(takeUntilDestroyed(this.destroy))
        .subscribe({
          next: (response: T) => {
            console.log(response);
            if (method === 'DELETE') {
              this.services.toast.show(ToastMessages.CartItemRemoved.success);
            }
            this.reloadBasketEvent();
          },
          error: console.error,
        });
    }
  
    private reloadBasketEvent(): void {
      this.services.eventBus.emit(new EmitEvent(Events.StateLoadGetBasket, { parentId: this.services.auth.getUser()?.id }));
      this.disabledQuantityButtons.set(false);
    }
  }
