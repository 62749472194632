<div class="cart-sidebar border-round-xl shadow-2 p-3 sm:p-4 overflow-y-auto text" [ngClass]="{
    'inSidebar': inSidebar(), 
  'border-noround	': inSidebar(), 
  'inCheckout	': inCheckout(), 
  'text-800	': editMode()}">

  @if (inSidebar()) {
  <div class="flex justify-content-between align-items-center border-bottom-1 pb-3 surface-border">
    <span class="font-medium text-lg lg:text-xl" [ngClass]="{ 'primary-purple-color': !editMode() }">
      <i class="pi pi-shopping-cart text-xl mr-2"></i>Your Order</span>
    @if (!inCheckout()) {
    <span class=""><i class="pi pi-times cursor-pointer h-2rem w-2rem flex align-items-center justify-content-center" 
      (click)="this.generalService.cartSidebarVisible.set(false)"></i>
    </span>
    }
  </div>
  }
  <div class="offcanvas-body">
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }" styleClass="customsidebar-scroll">
      <!-- @if (basket$().data !== null) {
  {{basket$().data.basket?.basketItems | json}}
  } -->



      @if (basket$().loading && !basket$().data) {

      <div class="h-full flex align-items-center justify-content-center">
        <p-progressSpinner ariaLabel="loading" />
      </div>


      }

      @if(editMode()) {

      @if (basket$().data !== null) {

      <div class="grid grid-nogutter">
        @for (product of basket$().data.basket?.basketItems; track product.id) {

          <app-checkout-sidebar-basket-item [basketItem]="product"></app-checkout-sidebar-basket-item>
        
        }



      </div>

      }


      <div class="flex w-full align-items-center justify-content-center">
        <span class="w-full saving-bg 500 text-white font-semibold px-2 py-2 border-round text-center">
          You have saved a total of 80 € 🙂
        </span>
      </div>

      <div class="py-2 m-0 surface-border">
        <div class="flex flex-column sm:flex-row gap-1 justify-content-between align-items-center">
          <span class="font-medium">Discount
            Code
            <div class="text-xs">
              Add here any discount codes you may have.
            </div>
            <div class="text-xs">
              Subject to Terms & Conditions.
            </div>
          </span>
          <span class="text-900">

            @if (isPromoCodeInputVisible()) {
            <div class="p-inputgroup mb-3">
              <input type="text" [(ngModel)]="promoCode" pInputText placeholder="Promo code" class="w-full">
              <button type="button" pButton pRipple label="Apply" [disabled]="!promoCode"></button>
            </div>
            } @else {

            <p-button class="promo_button" label="Add Code" [rounded]="true" severity="help" size="small"
              (click)="togglePromoCodeInputVisibility()" />

            }
          </span>
        </div>
      </div>


      <!-- <p-button type="submit" (click)="testUpdateBasketItem()" [rounded]="true" [size]="'small'"
        class="azure-bg border-round-3xl" styleClass=" border-round-3xl" styleClass="" label="test Update BasketItem"
        icon="pi pi-chevron-right" iconPos="right"></p-button> -->

      } @else {

      @if (basket$().data !== null) {

      @if (basket$().data.basket.basketItems.length === 0) {
      <div class="surface-section text-center px2 py-8 md:px-6 lg:px-4">

        <div class="flex flex-column align-items-center justify-content-center">

          <span
            class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle w-3rem h-3rem">
            <i class="pi pi-shopping-cart text-2xl"></i></span>
          <div class="text-xl text-900 font-bold mt-2">No items in your basket.</div>
          <p class="text-600 text-sm line-height-3 mb-1">
            Select the items you want to buy and add them to your basket.
          </p>
          @if (isGoToSelectNewPackageButtonVisible()) {
          <p>
            <p-button (click)="goToBuyPackage()" [rounded]="true" [outlined]="true" size="small"
              class="button_addon border-round-3xl" label="Buy a Package" icon="pi pi-chevron-right"
              iconPos="right"></p-button>
          </p>
          }
        </div>
      </div>
      }
      <ul class="list-none p-0 m-0">
        @for (product of basket$().data.basket.basketItems; track product) {
          <app-checkout-sidebar-basket-item [basketItem]="product" productViewType="mini"
          [sidebarShowDeleteCartItemsButton]="sidebarShowDeleteCartItemsButton()"></app-checkout-sidebar-basket-item>
        }

      </ul>
      }
      <!-- <p-button type="submit" (click)="testUpdateBasketItem()" [rounded]="true" [size]="'small'"
        class="azure-bg border-round-3xl" styleClass=" border-round-3xl" styleClass="" label="test Update BasketItem"
        icon="pi pi-chevron-right" iconPos="right"></p-button> -->
      }

      @if(showCheckoutButton() && !inSidebar()) {
      <div class="mt-4">
        <p-button type="submit" (click)="goToCheckout()" [rounded]="true" [size]="'large'"
          class="azure-bg border-round-3xl" styleClass=" border-round-3xl" styleClass="" label="Checkout"
          [disabled]="basket$().data.basket?.basketItems.length === 0" icon="pi pi-chevron-right"
          iconPos="right"></p-button>
      </div>
      }

    </p-scrollPanel>
  </div>

  @if (basket$().data !== null) {
  <div class="py-2 mt-3 border-top-1 surface-border mt-auto">
    <div class="flex justify-content-between align-items-center mb-3">
      <span class="">Subtotal</span>
      <span class="">{{basket$().data.basket?.totalPrice}} €</span>
    </div>
    @if (basket$().data.basket?.totalPackOfLessonsSavings > 0) {
    <div class="flex justify-content-between align-items-center mb-3">
      <span class="">Package Discount</span>
      <span class="">- {{basket$().data.basket?.totalPackOfLessonsSavings}} €</span>
    </div>
    }
    @if (basket$().data.basket?.totalGroupSavings > 0) {
    <div class="flex justify-content-between align-items-center mb-3">
      <span class="">Group Discount</span>
      <span class="">{{basket$().data.basket?.totalGroupSavings}} €</span>
    </div>
    }
    <div class="flex justify-content-between align-items-center border-top-1 surface-border">
      <span class="font-bold  my-3" [ngClass]="{ 'primary-purple-color': !editMode() }">Order Total</span>
      <span class="font-bold" [ngClass]="{ 'primary-purple-color': !editMode() }">{{basket$().data.basket?.totalPrice}}
        €</span>
    </div>
  </div>

  @if (inSidebar() && showCheckoutButton()) {
  <div class="mt-4 flex flex-column gap-2">
    <p-button type="submit" (click)="goToCheckout()" [rounded]="true" [size]="'large'"
      class=" border-round-3xl w-full" styleClass="azure-bg-border azure-bg-color border-round-3xl w-full" label="View Cart"
      [disabled]="basket$().data.basket?.basketItems.length === 0" icon="pi pi-chevron-right"
      [outlined]="true"
      iconPos="right"></p-button>

    <p-button type="submit" (click)="goToCheckoutStep(2)" [rounded]="true" [size]="'large'"
      class=" border-round-3xl w-full" styleClass="azure-bg-btn border-round-3xl w-full" label="Checkout"
      [disabled]="basket$().data.basket?.basketItems.length === 0" icon="pi pi-chevron-right"
      iconPos="right"></p-button>
  </div>
  }

  @if (showStepTwoButtonBottom()) {
  <div class="mt-2">
    <p-button type="submit" (click)="goToCheckoutStep(2)" [rounded]="true" [size]="'large'"
      [disabled]="basket$().data.basket.basketItems.length === 0" class="azure-bg border-round-3xl w-full"
      styleClass=" border-round-3xl w-full" label="Proceed to Checkout" icon="pi pi-chevron-right"
      iconPos="right"></p-button>
  </div>
  }

  }

</div>
<p-confirmDialog #cd>
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
      <div
        class="border-circle bg-red-300 text-white inline-flex justify-content-center align-items-center h-4rem w-4rem">
        <i class="pi pi-trash text-3xl"></i>
      </div>
      <span class="font-bold text-xl block mb-2 mt-4">
        {{ message.header }}
      </span>
      <p class="mb-0" [innerHTML]="message.message"></p>
      <div class="flex align-items-center gap-2 mt-4">
        <button pButton label="Yes" (click)="cd.accept()" class="w-8rem">
        </button>
        <button pButton label="Cancel" (click)="cd.reject()" class="p-button-outlined w-8rem ">
        </button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>